import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "primereact/tooltip";
import { useLocation } from "react-router-dom";
import {
  ResetState,
  // UserProfile,
  getAllSports,
  getTeamTypes,
} from "../../../store/action/userAction";
import { CheckPermission } from "../../../hooks/CheckPermission";
import { useGetUnder13User } from "../../../hooks/useGetUnder13User";
import {
  GetAllSubTeamByParentID,
  GetAllTeamData,
  GetTeamById,
} from "../../../store/action/teamAction";
// import { GetAllPermissionByOtherRole } from "../../../store/action/permissionAction";
// import TeamManagementSVG from "./LeftList/TeamManagementSVG";
// import MarketSVG from "./LeftList/MarketSVG";
// import StatsSVG from "./LeftList/StatsSVG";
// import CalendarSVG from "./LeftList/CalendarSVG";
// import FollowersSVG from "./LeftList/FollowersSVG";
import SettingsSVG from "./LeftList/SettingsSVG";
import MasterCalendarSVG from "./LeftList/MasterCalendarSVG";
import FacilityManagementSVG from "./LeftList/FacilityManagementSVG";
import RoleManageMentSVG from "./LeftList/RoleManagementSVG";
import MasterVideosSVG from "./LeftList/MasterVideosSVG";
// import PaymentCredentialsSVG from "./LeftList/PaymentCredentialsSVG";
import AuctionSVG from "./LeftList/AuctionSVG";
import ProductSVG from "./LeftList/ProductSVG";
import OrdersSVG from "./LeftList/OrdersSVG";
import StatisticsSVG from "./LeftList/StatisticsSVG";
import IdentitySVG from "./LeftList/IdentitySVG";
import SellSVG from "./LeftList/SellSVG";
import MyWishlistSVG from "./LeftList/MyWishlistSVG";
import OrderReceiptSVG from "./LeftList/OrderReceipt";
import CustomerServiceSVG from "./LeftList/CustomerServiceSVG";
import TeamPlayersCoachesSVG from "./LeftList/TeamPlayersCoachesSVG";
import TeamCalendarSVG from "./LeftList/TeamCalendarSVG";
import TeamPlaybookSVG from "./LeftList/TeamPlaybookSVG";
// import TeamFilesSVG from "./LeftList/TeamFilesSVG";
import TeamVideosSVG from "./LeftList/TeamVideosSVG";
import { GetAllPost, GetAllPostByUser } from "../../../store/action/postAction";
import { GetProfileListData } from "../../../store/action/profileListAction";
// import { SetCountryDialog } from "../../../store/action/otherAction";
import { GetAllTeamFollowers } from "../../../store/action/followAction";
import TeamMSGBoardSVG from "./LeftList/TeamMSGBoardSVG";
import {
  GetMessageBoardNotificationCount,
  GetNotificationCount,
  GetTeamMsgBoardNotificationCount,
} from "../../../store/action/notificationsAction";
import axios from "axios";

function LeftList() {
  const location = useLocation();
  const isUnder13 = useGetUnder13User();
  const { getProfileListData } = useSelector((state) => state.profileList);
  const [currentUser, setCurrentUser] = React.useState({
    iUserId: 0,
    vName: "",
    vImage: "",
    isSet: false,
  });
  // const [visible, setVisible] = React.useState(+false);
  // const [visibleStats, setVisibleStats] = React.useState(+false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfileData, sports, teamTypes } = useSelector(
    (state) => state.user
  );
  // const { getAllPermissionByOtherRoleData } = useSelector(
  //   (state) => state.permission
  // );
  const { vTeamSlug } = useParams();
  const { teamData } = useSelector((state) => state.team);
  const isAccessMasterCalendarPermit = CheckPermission(1);
  // const [nonInteractiveSport,setNonInteractiveSport] = React.useState([]);
  // const isViewPlaybookPermit = CheckPermission(6);
  // const isViewTeamCalendarPermit = CheckPermission(4);
  const [interactSportId, setInteractSportId] = React.useState();
  // const { state } = useLocation();
  const [isPlayer, setIsPlayer] = React.useState(+false);
  const { state } = useLocation();
  const [teamId, setTeamId] = React.useState("");
  const {
    // getAllNotificationsData,
    // getNotificationCountData,
    getTeamMsgBoardNotificationCountData,
  } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (state) {
      setTeamId(state.iTeamId);
      dispatch(
        GetTeamMsgBoardNotificationCount({
          iVisibleId: sessionStorage.getItem("id"),
          iTeamId: state.iTeamId,
        })
      );
    }
  }, [dispatch, state]);

  React.useEffect(() => {
    if (userProfileData && userProfileData.code === "200") {
      if (userProfileData.data && userProfileData.data.iRoleId === 4) {
        setIsPlayer(+true);
      }
    }
  }, [userProfileData]);

  React.useEffect(() => {
    if (!teamTypes) {
      dispatch(getTeamTypes());
    }
  }, [teamTypes, dispatch]);

  React.useEffect(() => {
    if (!sports) {
      dispatch(getAllSports());
    }
  }, [dispatch, sports]);

  React.useEffect(() => {
    const index =
      sports &&
      sports.findIndex(
        (item) =>
          item?.iSportId === teamData?.data?.iSportId &&
          item?.eSportType === "Interactive"
      );
    setInteractSportId(index);
  }, [sports, teamData]);

  React.useEffect(() => {
    if (!teamData) {
      const values = {
        iUserId: sessionStorage.getItem("id"),
        iTeamId: location?.state?.iTeamId,
      };
      if (parseInt(values.iUserId) > 0 && parseInt(values.iTeamId) > 0) {
        dispatch(GetTeamById(values));
      }
    }
  }, [dispatch, teamData, location]);

  // const handlePost = () => {
  //   dispatch(GetAllPost({ iUserId: sessionStorage.getItem("id"), page: 1 }));
  // };
  const handlePost = useCallback(() => {
    const parentId = sessionStorage.getItem("parentid");
    if (sessionStorage.getItem("isParent") === "Yes") {
      // dispatch(ResetState());
      dispatch(
        GetAllPostByUser({ iUserId: parentId, iFriendUserId: parentId })
      );
      dispatch(GetAllPost({ iUserId: parentId, page: 1 }));
      sessionStorage.setItem("id", parentId);
      sessionStorage.setItem("isParent", "No");
      sessionStorage.removeItem("parentid");

      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });

      dispatch(ResetState());
    } else {
      dispatch(GetAllPost({ iUserId: sessionStorage.getItem("id"), page: 1 }));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (sessionStorage.getItem("isParent") === "Yes") {
      dispatch(
        GetProfileListData({ iUserId: sessionStorage.getItem("parentid") })
      );
      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });
    } else {
      dispatch(GetProfileListData({ iUserId: sessionStorage.getItem("id") }));
      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !currentUser.isSet &&
      getProfileListData &&
      getProfileListData.code === "200" &&
      getProfileListData.data
    ) {
      if (
        sessionStorage.getItem("isParent") === "Yes" &&
        getProfileListData.data.ChildTeamMember &&
        getProfileListData.data.ChildTeamMember.length > 0
      ) {
        const childData = getProfileListData.data.ChildTeamMember.find(
          (item) => item.iUserId.toString() === sessionStorage.getItem("id")
        );
        if (childData && Object.keys(childData).length > 0) {
          setCurrentUser({
            iUserId: childData.user.iUserId,
            vName: `${
              childData.user?.vFirstName &&
              childData.user?.vFirstName.length > 0
                ? childData.user?.vFirstName.charAt(0)
                : ""
            }${
              childData.user?.vLastName && childData.user?.vLastName.length > 0
                ? childData.user?.vLastName.charAt(0)
                : ""
            }`,
            vImage: childData.user?.vImage,
            isSet: true,
          });
        }
      } else {
        if (
          getProfileListData.data.userData &&
          getProfileListData.data.userData.iUserId.toString() ===
            sessionStorage.getItem("id")
        ) {
          setCurrentUser({
            iUserId: getProfileListData.data.userData.iUserId,
            vName: `${
              getProfileListData.data.userData?.vFirstName &&
              getProfileListData.data.userData?.vFirstName.length > 0
                ? getProfileListData.data.userData?.vFirstName.charAt(0)
                : ""
            }${
              getProfileListData.data.userData?.vLastName &&
              getProfileListData.data.userData?.vLastName.length > 0
                ? getProfileListData.data.userData?.vLastName.charAt(0)
                : ""
            }`,
            vImage: getProfileListData.data.userData?.vImage,
            isSet: true,
          });
        }
      }
    }
  }, [getProfileListData, currentUser]);

  const handleParentProfile = React.useCallback(
    (data) => {
      dispatch(
        GetAllPostByUser({ iUserId: data.iUserId, iFriendUserId: data.iUserId })
      );
      dispatch(GetAllPost({ iUserId: data.iUserId, page: 1 }));

      dispatch(ResetState());
      if (data && Object.keys(data).length > 0) {
        sessionStorage.setItem("id", data.iUserId);
        sessionStorage.setItem("isParent", "No");
        sessionStorage.removeItem("parentid");
        setCurrentUser({
          iUserId: 0,
          vName: "",
          vImage: "",
          isSet: false,
        });
        navigate("/profile");
      }
    },
    [navigate, dispatch]
  );

  const handleChildProfile = React.useCallback(
    (childId, parentId, data) => {
      dispatch(ResetState());
      if (
        sessionStorage.getItem("parentid") &&
        sessionStorage.getItem("parentid").length > 0
      ) {
        sessionStorage.setItem("parentid", sessionStorage.getItem("parentid"));
      } else {
        sessionStorage.setItem("parentid", parentId);
      }
      sessionStorage.setItem("id", childId);
      sessionStorage.setItem("isParent", "Yes");
      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });
      navigate("/profile");
    },
    [navigate, dispatch]
  );

  const handleTeam = React.useCallback(
    (id) => {
      sessionStorage.setItem("teamId", id);

      if (location.pathname === "/teams-details") {
        const values = {
          iTeamId: id,
          iUserId: sessionStorage.getItem("id"),
        };

        if (parseInt(values.iUserId) > 0 && parseInt(values.iTeamId) > 0) {
          dispatch(GetTeamById(values));
        }

        dispatch(
          GetAllTeamFollowers({
            iUserId: sessionStorage.getItem("id"),
            iTeamId: id,
          })
        );

        dispatch(
          GetAllSubTeamByParentID({
            iParentId: id,
          })
        );
        dispatch(GetAllTeamData());
      } else {
        navigate("/teams-details", { state: { iTeamId: id } });
      }
    },
    [navigate, location, dispatch]
  );

  const handleNavigate = React.useCallback(
    (id) => {
      navigate("/business-profile", { state: { iBussinesTranId: id } });
    },
    [navigate]
  );

  const handleTeamMsgBoardNotificationSeen = React.useCallback(async () => {
    // dispatch(SetNotificationSeen({ iVisibleId: sessionStorage.getItem("id") }));
    if (teamId) {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/notification/seenMessageBoardNotificationData`,
        { iVisibleId: sessionStorage.getItem("id"), iTeamId: teamId }
      );
      if (resData.status === 200 && +resData.data.code === +0) {
        dispatch(
          GetTeamMsgBoardNotificationCount({
            iVisibleId: sessionStorage.getItem("id"),
            iTeamId: teamId,
          })
        );
        dispatch(
          GetMessageBoardNotificationCount({
            iVisibleId: sessionStorage.getItem("id"),
          })
        );
        dispatch(
          GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
        );
      }
    }
  }, [dispatch, teamId]);

  const handleTeamMsgBoardClick = useCallback(() => {
    handleTeamMsgBoardNotificationSeen();
    if (location.pathname !== "/team-message-board-posts") {
      navigate("/team-message-board-posts", {
        state: { iTeamId: teamId },
      });
    }
  }, [
    handleTeamMsgBoardNotificationSeen,
    location?.pathname,
    navigate,
    teamId,
  ]);

  useEffect(() => {
    if (location.pathname === "/team-message-board-posts" && teamId) {
      handleTeamMsgBoardNotificationSeen();
    }
  }, [handleTeamMsgBoardNotificationSeen, location.pathname, teamId]);

  return location.pathname === `/teams-details/${vTeamSlug}` ? (
    ""
  ) : (
    <div className="left-side-menu collapse" id="quicksidebarContent">
      <Link
        to={"/my-teams"}
        className="left-logo"
        state={{ prevPath: location.pathname }}
        onClick={() => handlePost()}
      >
        <img src="/assets/img/left-Logo.png" alt="" />
      </Link>
      {location.pathname === "/profile" ||
      location.pathname === `/auth/${userProfileData?.data?.vPersonalSlug}` ||
      location.pathname ===
        `/personal/${userProfileData?.data?.vPersonalSlug}` ||
      location.pathname === `/team/auth/${vTeamSlug}` ||
      location.pathname === "/edit-team-details" ||
      location.pathname === "/settings" ||
      location.pathname === "/followers" ||
      location.pathname === "/calendar" ||
      location.pathname === "/my-playbook" ||
      location.pathname === "/team-chat" ||
      location.pathname === "/search-filter" ||
      location.pathname === "/select-sport" ||
      location.pathname === "/create-team" ||
      location.pathname === "/create-organization" ||
      location.pathname === "/edit-organization" ||
      location.pathname === "/add-member" ||
      location.pathname === "/chat" ||
      location.pathname === "/teams-details" ||
      location.pathname === "/message-board-teams" ||
      location.pathname === "/message-board-posts" ||
      location.pathname === "/message-board-settings" ||
      location.pathname === "/groups" ? (
        <>
          <div className="left-ac-hanlder">
            {/* <button
            className="cr-btn"
            onClick={() => dispatch(SetCountryDialog(true))}
          >
            <i className="far fa-plus"></i>
          </button> */}

            <ul className="ac-list">
              {getProfileListData && getProfileListData.code === "200" ? (
                <>
                  {getProfileListData.data.userData ? (
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          handleParentProfile(getProfileListData.data.userData);
                        }}
                        type="button"
                      >
                        <Tooltip target=".custom-target-name" />
                        <div className="image-box">
                          <img
                            src={getProfileListData.data.userData?.vImage}
                            data-pr-tooltip={`${
                              getProfileListData.data.userData?.vFirstName &&
                              getProfileListData.data.userData?.vFirstName
                                .length > 0
                                ? getProfileListData.data.userData?.vFirstName
                                : ""
                            } ${
                              getProfileListData.data.userData?.vLastName &&
                              getProfileListData.data.userData?.vLastName
                                .length > 0
                                ? getProfileListData.data.userData?.vLastName
                                : ""
                            }`}
                            data-pr-position="right"
                            data-pr-at="right+5 center"
                            data-pr-my="left center-2"
                            style={{ fontSize: "2rem", cursor: "pointer" }}
                            className="custom-target-name"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                            }}
                            alt=""
                          />
                        </div>

                        {/* <div className="info">
                  <h3>
                    {`${
                      getProfileListData.data.userData?.vFirstName &&
                      getProfileListData.data.userData?.vFirstName.length > 0
                        ? getProfileListData.data.userData?.vFirstName
                        : ""
                    } ${
                      getProfileListData.data.userData?.vLastName &&
                      getProfileListData.data.userData?.vLastName.length > 0
                        ? getProfileListData.data.userData?.vLastName
                        : ""
                    }`}
                  </h3>
                  <p>Personal</p>
                </div> */}
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                  <>
                    {getProfileListData.data &&
                    getProfileListData.data.ChildTeamMember &&
                    getProfileListData.data.ChildTeamMember.length > 0 ? (
                      getProfileListData.data.ChildTeamMember.map(
                        (item, key) => (
                          <li key={key}>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                handleChildProfile(
                                  item.user?.iUserId,
                                  sessionStorage.getItem("id"),
                                  item
                                );
                              }}
                              type="button"
                            >
                              <div className="image-box">
                                <Tooltip
                                  target={`.custom-child-name${item.user.iUserId}`}
                                />
                                <img
                                  src={item.user?.vImage}
                                  data-pr-tooltip={`${item.user?.vFirstName} ${item.user?.vLastName}`}
                                  data-pr-position="right"
                                  data-pr-at="right+5 center"
                                  data-pr-my="left center-2"
                                  style={{
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }}
                                  className={`custom-child-name${item.user.iUserId}`}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                  }}
                                  alt=""
                                />
                              </div>
                              {/* <div className="info">
                        <h3>
                          {`${item.user?.vFirstName} ${item.user?.vLastName}`}
                        </h3>
                        <p>Child</p>
                      </div> */}
                            </button>
                          </li>
                        )
                      )
                    ) : (
                      <></>
                    )}
                    {getProfileListData?.data?.userData &&
                    getProfileListData?.data?.userData
                      .bussines_profile_transactions &&
                    getProfileListData?.data?.userData
                      .bussines_profile_transactions.length > 0 ? (
                      getProfileListData?.data?.userData.bussines_profile_transactions.map(
                        (item, key) => (
                          <li key={key}>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                handleNavigate(item.iBussinesTranId);
                              }}
                              type="button"
                            >
                              <div className="image-box">
                                <Tooltip
                                  target={`.custom-business-name${item.iBussinesTranId}`}
                                />
                                <img
                                  src={
                                    item?.vBusinessImage
                                      ? item?.vBusinessImage
                                      : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
                                  }
                                  data-pr-tooltip={`${item?.vBusinessName}`}
                                  data-pr-position="right"
                                  data-pr-at="right+5 center"
                                  data-pr-my="left center-2"
                                  style={{
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }}
                                  className={`custom-business-name${item.iBussinesTranId}`}
                                  alt="coach"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                  }}
                                />
                              </div>
                              {/* <div className="info">
                          <h3>{item?.vBusinessName}</h3>
                          <p>Business</p>
                        </div> */}
                            </button>
                          </li>
                        )
                      )
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {getProfileListData &&
                    getProfileListData?.code === "200" ? (
                      getProfileListData?.data?.TeamsData.map((item, key) =>
                        item && item.Team_Details ? (
                          <li key={key}>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                handleTeam(item.iTeamId);
                              }}
                              type="button"
                            >
                              <div className="image-box">
                                <Tooltip
                                  target={`.custom-team-name${item.iTeamId}`}
                                />
                                <img
                                  src={item?.Team_Details?.vTeamLogo}
                                  data-pr-tooltip={`${item.Team_Details.vTeamName}`}
                                  data-pr-position="right"
                                  data-pr-at="right+5 center"
                                  data-pr-my="left center-2"
                                  style={{
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                  }}
                                  className={`custom-team-name${item.iTeamId}`}
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                                  }}
                                />
                              </div>

                              {/* <div className="info">
                          <h3>{item.Team_Details.vTeamName}</h3>
                          <p className="main-title">
                            <span>
                              {teamTypes &&
                              teamTypes.length > 0 &&
                              teamTypes.find(
                                (value) =>
                                  value.iTeamTypeId ===
                                  item.Team_Details.iTeamTypeId
                              )
                                ? teamTypes.find(
                                    (value) =>
                                      value.iTeamTypeId ===
                                      item.Team_Details.iTeamTypeId
                                  ).vTitle
                                : ""}
                            </span>
                          </p>
                        </div> */}
                            </button>
                          </li>
                        ) : (
                          <React.Fragment key={key}></React.Fragment>
                        )
                      )
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </>
      ) : (
        <div
          className="inner-menu"
          // style={{
          //   overflow: visible || visibleStats ? "visible" || visibleStats : "",
          // }}
        >
          {/* {location.pathname === "/business-products-stats" ||
        location.pathname === "/market-place" ||
        location.pathname === "/business-profile" ? (
          <></>
        ) : (
          <Link
            className="menu"
            to={"/my-teams"}
            state={{ prevPath: location.pathname }}
          >
            <div className="image-icon">
              <TeamManagementSVG />
            </div>
            <p>Teams</p>
          </Link>
        )} */}

          {location.pathname === "/my-teams" ||
          location.pathname === `/subscription` ||
          location.pathname === "/master-calendar" ||
          location.pathname === "/master-videos" ||
          location.pathname === "/payment-methods" ||
          location.pathname === "/create-facility" ||
          location.pathname === "/roles" ? (
            <>
              <Link
                className={"menu"}
                // state={{ prevPath: location.pathname }}
                to={"/profile"}
              >
                <div className="image-icon">
                  {userProfileData && userProfileData.data ? (
                    <img
                      width={50}
                      height={50}
                      style={{ borderRadius: 50 }}
                      src={userProfileData.data.vImage}
                      alt=""
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <p>Profile</p>
              </Link>
              {isPlayer ? (
                <></>
              ) : // isAccessMasterCalendarPermit ? (
              //   <Link
              //     className={
              //       location.pathname === "/master-calendar"
              //         ? "menu active"
              //         : "menu"
              //     }
              //     state={{ prevPath: location.pathname }}
              //     to={"/master-calendar"}
              //   >
              //     <div className="image-icon">
              //       <MasterCalendarSVG />
              //     </div>
              //     <p>Organization Calendar</p>
              //   </Link>
              // ) : (
              //   <></>
              // )
              isAccessMasterCalendarPermit ? (
                <Link
                  className={
                    location.pathname === "/master-calendar"
                      ? "menu active"
                      : "menu"
                  }
                  state={{ prevPath: location.pathname }}
                  to={"/master-calendar"}
                >
                  <div className="image-icon">
                    <MasterCalendarSVG />
                  </div>
                  <p>Organization Calendar</p>
                </Link>
              ) : (
                <></>
              )}
              
              {isPlayer ||
              (userProfileData &&
                userProfileData?.code === "200" &&
                userProfileData?.data &&
                userProfileData?.data.iRoleId &&
                userProfileData?.data.iRoleId === 9) ? (
                <></>
              ) : userProfileData &&
                userProfileData?.code === "200" &&
                userProfileData?.data &&
                userProfileData?.data.iRoleId &&
                userProfileData?.data.iRoleId === 1 ? (
                <Link
                  className={
                    location.pathname === "/create-facility"
                      ? "menu active"
                      : "menu"
                  }
                  state={{ prevPath: location.pathname }}
                  to={"/create-facility"}
                >
                  <div className="image-icon">
                    <FacilityManagementSVG />
                  </div>
                  <p>Facility Management</p>
                </Link>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {isUnder13 ? (
            <></>
          ) : location.pathname === "/my-teams" ||
            location.pathname === `/subscription` ||
            location.pathname === "/master-calendar" ||
            location.pathname === "/roles" ||
            location.pathname === "/master-videos" ||
            location.pathname === "/create-facility" ||
            location.pathname === "/team-chat" ||
            location.pathname === "/select-sport" ||
            location.pathname === "/create-team" ||
            location.pathname === "/create-organization" ||
            location.pathname === "/edit-organization" ||
            location.pathname === "/add-member" ||
            location.pathname === "/payment-methods" ? (
            sessionStorage.getItem("roleId") === "1" ? (
              <Link
                className={
                  location.pathname === "/roles" ? "menu active" : "menu"
                }
                to={"/roles"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <RoleManageMentSVG />
                </div>
                <p>Role Management</p>
              </Link>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          {/* {isUnder13 ? (
          <></>
        ) : location.pathname === "/business-profile" ? (
          <>
            <Link
              className="menu"
              to={"/market-place"}
              state={{ prevPath: location.pathname }}
            >
              <div className="image-icon">
                <MarketSVG />
              </div>
              <p>Market</p>
            </Link>
          </>
        ) : (
          <></>
        )} */}
          {isUnder13 ? (
            <></>
          ) : location.pathname === "/business-profile" ? (
            <>
              <Link
                className="menu"
                to={"/business-profile"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <AuctionSVG />
                </div>
                <p>Auction</p>
              </Link>
              <Link
                className="menu"
                to={"/business-profile"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <ProductSVG />
                </div>
                <p>Product</p>
              </Link>
              <Link
                className="menu"
                to={"/business-profile"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <OrdersSVG />
                </div>
                <p>Orders</p>
              </Link>
              <Link
                className="menu"
                to={"/business-profile"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <StatisticsSVG />
                </div>
                <p>Statistics</p>
              </Link>
            </>
          ) : (
            <></>
          )}
          {isUnder13 ? (
            <></>
          ) : location.pathname === "/business-products-stats" ||
            location.pathname === "/market-place" ? (
            // <>
            <Link
              className="menu"
              state={{ prevPath: location.pathname }}
              to={"/business-products-stats"}
            >
              <div className="image-icon">
                <IdentitySVG />
              </div>
              <p>Identity</p>
            </Link>
          ) : (
            <></>
          )}
          {isUnder13 ? (
            <></>
          ) : location.pathname === "/market-place" ||
            location.pathname === "/business-products-stats" ? (
            <>
              <Link
                className="menu"
                to={"/business-products-stats"}
                // data-bs-toggle="modal"
                // data-bs-target="#createPostModal"
                state={{
                  prevPath: location.pathname,
                }}
              >
                <div className="image-icon">
                  <SellSVG />
                </div>
                <p>Sell</p>
              </Link>

              <Link
                className="menu"
                to={"/market-place"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <MyWishlistSVG />
                </div>
                <p>My Wishlist</p>
              </Link>
              <Link
                className="menu"
                to={"/market-place"}
                state={{ prevPath: location.pathname }}
              >
                <div className="image-icon">
                  <OrderReceiptSVG />
                </div>
                <p>Order & Receipt</p>
              </Link>
            </>
          ) : (
            <></>
          )}
          {isUnder13 ? (
            <></>
          ) : location.pathname === "/business-products-stats" ||
            location.pathname === "/market-place" ? (
            <Link
              className="menu"
              state={{ prevPath: location.pathname }}
              to={"/business-products-stats"}
            >
              <div className="image-icon">
                <CustomerServiceSVG />
              </div>
              <p>Customer Service</p>
            </Link>
          ) : (
            <></>
          )}
          {
            // location.pathname === "/edit-team-details" ||
            location.pathname === "/add-team-member" ||
            location.pathname === "/add-coach" ||
            location.pathname === "/team-playbook" ||
            location.pathname === "/team-files" ||
            location.pathname === "/team-videos" ||
            location.pathname === "/team-calendar" ||
            location.pathname === "/add-head-coach" ||
            location.pathname === "/add-team-member" ||
            location.pathname === "/team-message-board-posts" ||
            location.pathname === "/team-message-board-settings" ? (
              <>
                <Link
                  className={
                    location.pathname === "/add-team-member"
                      ? "menu active"
                      : "menu"
                  }
                  to={"/add-team-member"}
                  state={{
                    prevPath: location.pathname,
                    iTeamId: location?.state?.iTeamId,
                  }}
                >
                  <div className="image-icon">
                    <TeamPlayersCoachesSVG />
                  </div>
                  <p>Team Roster</p>
                  {/* <p>Team Players & Coaches</p> */}
                </Link>
                {/* {teamData &&
              teamData?.code === "200" &&
              teamData?.data &&
              teamData?.data.User_Role_Id &&
              teamData?.data &&
              teamData?.data.User_Role_Id === 4} */}
                <Link
                  className={
                    location.pathname === "/team-calendar"
                      ? "menu active"
                      : "menu"
                  }
                  state={{
                    prevPath: location.pathname,
                    iTeamId: location?.state?.iTeamId,
                  }}
                  to={"/team-calendar"}
                >
                  <div className="image-icon">
                    <TeamCalendarSVG />
                  </div>
                  <p>Team Calendar</p>
                </Link>

                {/* {isPlayer ? (
                isViewTeamCalendarPermit ? (
                  <Link
                    className="menu"
                    state={{
                      prevPath: location.pathname,
                      iTeamId: location?.state?.iTeamId,
                    }}
                    to={"/team-calendar-new"}
                  >
                    <div className="image-icon">
                      <TeamCalendarSVG />
                    </div>
                    <p>Team Calendar New</p>
                  </Link>
                ) : (
                  <></>
                )
              ) : getAllPermissionByOtherRoleData &&
                getAllPermissionByOtherRoleData.code === "200" &&
                getAllPermissionByOtherRoleData.data &&
                getAllPermissionByOtherRoleData.data.find(
                  (item) => item.iModuleMasterlId === 4
                ) ? (
                <Link
                  className="menu"
                  state={{
                    prevPath: location.pathname,
                    iTeamId: location?.state?.iTeamId,
                  }}
                  to={"/team-calendar-new "}
                >
                  <div className="image-icon">
                    <TeamCalendarSVG />
                  </div>
                  <p>Team Calendar New</p>
                </Link>
              ) : (
                <></>
              )} */}

                {interactSportId > -1 ? (
                  <Link
                    className={
                      location.pathname === "/team-playbook"
                        ? "menu active"
                        : "menu"
                    }
                    to={"/team-playbook"}
                    state={{
                      prevPath: location.pathname,
                      iTeamId:
                        location.state && location.state.iTeamId
                          ? location.state.iTeamId
                          : sessionStorage.getItem("teamId"),
                    }}
                  >
                    <div className="image-icon">
                      <TeamPlaybookSVG />
                    </div>
                    <p>Team Playbook</p>
                  </Link>
                ) : (
                  <></>
                )}

                <div
                  className={
                    location.pathname === "/team-message-board-posts"
                      ? "menu active set-cursor-pointer"
                      : "menu set-cursor-pointer"
                  }
                  onClick={handleTeamMsgBoardClick}
                  // to="/team-message-board-posts"
                  // state={{ iTeamId: teamId }}
                  // to={{
                  //   pathname: "/team-message-board-posts",
                  //   state: {
                  //     iTeamId: teamId,
                  //   },
                  // }}
                >
                  <div
                    className="image-icon"
                    style={{
                      position: "relative",
                    }}
                  >
                    {getTeamMsgBoardNotificationCountData &&
                    getTeamMsgBoardNotificationCountData.code === "200" &&
                    getTeamMsgBoardNotificationCountData.data &&
                    getTeamMsgBoardNotificationCountData.data.eSeenCount ? (
                      getTeamMsgBoardNotificationCountData.data.eSeenCount ===
                      0 ? (
                        <></>
                      ) : (
                        <span
                          className="notification-count"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#ff0000",
                            position: "absolute",
                            top: "-3px",
                            right: "-23px",
                            color: "#fff",
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "15px",
                          }}
                        >
                          {getTeamMsgBoardNotificationCountData.data.eSeenCount}
                        </span>
                      )
                    ) : (
                      <></>
                    )}
                    <TeamMSGBoardSVG />
                  </div>
                  <p>Team Message Board</p>
                </div>

                <Link
                  className={
                    location.pathname === "/team-videos"
                      ? "menu active"
                      : "menu"
                  }
                  to={"/team-videos"}
                  state={{ prevPath: location.pathname }}
                >
                  <div className="image-icon">
                    <TeamVideosSVG />
                  </div>
                  <p>Team Videos</p>
                </Link>
                {/* <Link
                  className={
                    location.pathname === "/team-files" ? "menu active" : "menu"
                  }
                  to={"/team-files"}
                  state={{ prevPath: location.pathname }}
                >
                  <div className="image-icon">
                    <TeamFilesSVG />
                  </div>
                  <p>Team Files</p>
                </Link> */}
              </>
            ) : (
              <></>
            )
          }
          {/* Master videos code start */}
          {isUnder13 ? (
            <></>
          ) : location.pathname === "/my-teams" ||
            location.pathname === `/subscription` ||
            location.pathname === "/master-videos" ||
            location.pathname === "/master-calendar" ||
            location.pathname === "/payment-methods" ||
            location.pathname === "/roles" ||
            location.pathname === "/create-facility" ? (
            isPlayer ||
            (teamData &&
              teamData?.code === "200" &&
              teamData?.data &&
              teamData?.data.User_Role_Id &&
              teamData?.data.User_Role_Id === 4) ? (
              <></>
            ) : userProfileData &&
              userProfileData?.code === "200" &&
              userProfileData?.data &&
              userProfileData?.data.iRoleId &&
              userProfileData?.data.iRoleId === 1 ? (
              <Link
                className={
                  location.pathname === "/master-videos"
                    ? "menu active"
                    : "menu"
                }
                state={{ prevPath: location.pathname }}
                to={"/master-videos"}
              >
                <div className="image-icon">
                  <MasterVideosSVG />
                </div>
                <p>Master Videos</p>
              </Link>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          {/* Master videos code end */}
          {/* {location.pathname === "/my-teams" ||
      // location.pathname === `/subscription` ||
      location.pathname === "/master-videos" ||
      location.pathname === "/payment-methods" ||
      location.pathname === "/master-calendar" ||
      location.pathname === "/create-facility" ||
      location.pathname === "/roles" ? (
        isPlayer ||
        (teamData &&
          teamData?.code === "200" &&
          teamData?.data &&
          teamData?.data.User_Role_Id &&
          teamData?.data &&
          teamData?.data.User_Role_Id === 4) ? (
          <></>
        ) : (
          <Link
            className="menu"
            state={{ prevPath: location.pathname }}
            to={"/payment-methods"}
          >
            <div className="image-icon">
              <PaymentCredentialsSVG />
            </div>
            <p>Payment Credentials</p>
          </Link>
        )
      ) : (
        <></>
      )} */}
          {teamData?.code === "200" &&
          teamData?.data &&
          teamData?.data.User_Role_Id &&
          teamData?.data &&
          teamData?.data.User_Role_Id === 4 ? (
            <></>
          ) : (
            <Link
              className="menu"
              to={
                location.pathname === "/add-team-member"
                  ? "/teams-settings"
                  : location.pathname === "/teams-details" ||
                    location.pathname === "/edit-team-details"
                  ? "/edit-team-details"
                  : "/settings"
              }
              state={{ prevPath: location.pathname }}
            >
              <div className="image-icon">
                <SettingsSVG />
              </div>
              <p>Settings</p>
            </Link>
          )}

          {/* <Link
        className="menu"
        to={
          location.pathname === "/teams-details" ||
          location.pathname === "/edit-team-details"
            ? "/edit-team-details"
            : "/edit-profile"
        }
        state={{ prevPath: location.pathname }}
      >
        <div className="image-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.564"
            height="26.564"
            viewBox="0 0 26.564 26.564"
            className="icon-feather-settings"
          >
            <g
              id="Icon_feather-settings"
              data-name="Icon feather-settings"
              transform="translate(-0.6 -0.6)"
            >
              <path
                id="Path_8"
                data-name="Path 8"
                d="M20.254,16.877A3.377,3.377,0,1,1,16.877,13.5,3.377,3.377,0,0,1,20.254,16.877Z"
                transform="translate(-2.995 -2.995)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.8"
              />
              <path
                id="Path_9"
                data-name="Path 9"
                d="M22.212,17.259a1.857,1.857,0,0,0,.371,2.049l.068.068a2.253,2.253,0,1,1-3.186,3.186l-.068-.068a1.872,1.872,0,0,0-3.174,1.328v.191a2.251,2.251,0,0,1-4.5,0v-.1a1.857,1.857,0,0,0-1.216-1.7,1.857,1.857,0,0,0-2.049.371l-.068.068A2.253,2.253,0,1,1,5.2,19.465l.068-.068a1.872,1.872,0,0,0-1.328-3.174H3.751a2.251,2.251,0,0,1,0-4.5h.1a1.857,1.857,0,0,0,1.7-1.216,1.857,1.857,0,0,0-.371-2.049l-.068-.068A2.253,2.253,0,1,1,8.3,5.2l.068.068a1.857,1.857,0,0,0,2.049.371h.09a1.857,1.857,0,0,0,1.126-1.7V3.751a2.251,2.251,0,0,1,4.5,0v.1a1.872,1.872,0,0,0,3.174,1.328l.068-.068A2.253,2.253,0,1,1,22.561,8.3l-.068.068a1.857,1.857,0,0,0-.371,2.049v.09a1.857,1.857,0,0,0,1.7,1.126h.191a2.251,2.251,0,0,1,0,4.5h-.1a1.857,1.857,0,0,0-1.7,1.126Z"
                transform="translate(0 0)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.8"
              />
            </g>
          </svg>
        </div>
        <p>Settings</p>
      </Link> */}
          <div className="imp-option">
            {/* {location.pathname === `/master-calendar` ||
        location.pathname === `/teams-details` ||
        location.pathname === `/subscription` ||
        location.pathname === `/add-team-member` ||
        location.pathname === `/team-calendar` ||
        location.pathname === `/team-playbook` ||
        location.pathname === `/team-files` ||
        location.pathname === `/team-videos` ||
        location.pathname === `/create-facility` ||
        location.pathname === `/roles` ||
        location.pathname === "/select-sport" ||
        location.pathname === `/master-videos` ||
        location.pathname === `/payment-methods` ||
        location.pathname === `/create-team` ||
        location.pathname === "/my-teams" ? ( */}
          <div className="imp-area Subscription-app">
            <div
              className="image-box"
              onClick={() => navigate("/subscription")}
            >
              <img src="/assets/img/fi-rr-diamond.svg" alt="" />
            </div>
            <p>Subscription</p>
          </div>
        {/*  ) : (
         <></>
         )} */}

            <div
              className="imp-area download-app"
              data-bs-toggle="modal"
              data-bs-target="#downloadapp"
              type="button"
            >
              <div className="image-box">
                <img src="/assets/img/icon-download.svg" alt="" />
              </div>
              <p>Download App</p>
            </div>
          </div>
        </div>
      )}

      <div className="imp-option">
        <div
          className="imp-area download-app"
          data-bs-toggle="modal"
          data-bs-target="#downloadapp"
          type="button"
        >
          <div className="image-box">
            <img src="/assets/img/icon-download.svg" alt="" />
          </div>
          <p>Download App</p>
        </div>
      </div>
    </div>
  );
}

export default LeftList;
